<div class="machine-details">
  <div
    *ngIf="isCrossFunctionalForProductQualityActive"
    class="global-flex global-flex-direction__row  global-justify__center">
    <button
      type="button"
      class="common-button global-margin__space-right"
      (click)="returnToProductQuality()"
    >{{'common.return' | translate}}</button>
    <button
      type="button"
      class="common-button"
      (click)="addMachineToProductQuality()"
    >{{'common.assign' | translate}}</button>
  </div>
  <div
    class="machine-details__header">
    <h2>{{'production.technical-details' | translate}}</h2>
  </div>
  <div class="machine-details__body">
    <div
      *ngIf="!hideMachineDetails"
      class="machine-details__body__basic">
      <form
        *ngIf="formGroup"
        class="base-edit__base-form"
        [formGroup]="formGroup"
        (ngSubmit)="onSubmit()">
        <div class="machine-details__form">
          <div class="machine-details__form__container">
            <div class="machine-details__form__container__element">
              <label>{{'production.name' | translate}}</label>
              <input
                formControlName="name"
                type="text">
              <span
                [ngClass]="nameControl.invalid && (nameControl.dirty || nameControl.touched) ? 'visibility--true' : 'visibility--false'"
                class="global-error-message">
                {{'common.field-required' | translate}}
              </span>
            </div>
            <div class="machine-details__form__container__element">
              <label>{{'production.machine-type' | translate}}</label>
              <p-dropdown
                class="user-role__action-container__add-role__buttons__select"
                formControlName="type"
                optionLabel="label"
                optionValue="value"
                placeholder="{{'production.machine-type' | translate}}"
                [options]="availableDeviceTypeOptions">
              </p-dropdown>

              <span
                class="global-error-message"
                [ngClass]="typeControl.invalid && (typeControl.dirty || typeControl.touched) ? 'visibility--true' : 'visibility--false'"
              >{{'common.field-required' | translate}}</span>
            </div>
            <div class="machine-details__form__container__element">
              <label>{{'production.cost-center' | translate}}</label>
              <input
                formControlName="costCenter"
                type="number">
              <span
                [ngClass]="costCenterControl.invalid && (costCenterControl.dirty || costCenterControl.touched) ? 'visibility--true' : 'visibility--false'"
                class="global-error-message">
                {{'common.invalid-number-value' | translate}}
              </span>
            </div>
            <div class="machine-details__form__container__element">
              <label for="maintenanceCostPerManufacturedUnit">{{"production.maintenance-cost-per-manufactured-unit" | translate}}</label>
              <input
                id="maintenanceCostPerManufacturedUnit"
                formControlName="maintenanceCostPerManufacturedUnit"
                type="number">
              <span
                [ngClass]="maintenanceCostPerManufacturedUnitControl.invalid && (maintenanceCostPerManufacturedUnitControl.dirty || maintenanceCostPerManufacturedUnitControl.touched) ? 'visibility--true' : 'visibility--false'"
                class="global-error-message"
              >{{'common.invalid-number-value' | translate}}</span>
            </div>
          </div>
          <button
            *ngIf="!isCrossFunctionalForProductQualityActive"
            type="submit"
            [disabled]="formGroup.invalid"
          >{{'common.submit' | translate}}
          </button>
        </div>
      </form>
    </div>
    <div style="display: flex; align-items: center;">
      <app-workload-tiles
        [instanceId]="currentDeviceModel.id"
        [workloadList]="workloadList"
        [isWorkspaceGroup]="false"
        (fullHistoryEmitter)="toggleMachineDetailsFlag()"
      ></app-workload-tiles>
    </div>
  </div>
</div>
