import {Component, Input, OnInit} from '@angular/core';
import {MachineModel, ProductionDeviceModel} from '../../../../core/sdk/model-productiondevices';
import {MachineService} from '../../../../shared/services/production/machine.service';
import {NotificationService} from '../../../../shared/services/notification.service';
import {NotificationMessageType} from '../../../../shared/enums/NotificationMessageType';
import {Utility} from '../../../../shared/utilities/utility';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConstanceValues} from '../../../../shared/constance-values/constance-values';
import {CrossFunctionalService} from '../../../../shared/services/cross-functional.service';
import {Router} from '@angular/router';
import {PTableControlService} from '../../../../shared/services/p-table-control.service';
import {WorkloadService} from '../../../../shared/services/production/workload.service';
import {SearchCriteriaService} from '../../../../core/search-criteria.service';
import {Workload} from '../../../../core/sdk/bighero-model';
import {SpinnerService} from '../../../../shared/services/spinner.service';
import {SelectItem} from 'primeng/api';
import {ProductionDeviceModelService} from '../../../../shared/services/production/production-device-model.service';

@Component({
    selector: 'app-machine-details',
    templateUrl: './machine-details.component.html',
    standalone: false
})
export class MachineDetailsComponent implements OnInit {

  public formGroup: FormGroup;
  public isCrossFunctionalForProductQualityActive = false;
  public workloadList: Workload[];
  public hideMachineDetails = false;

  @Input() currentDeviceModel: MachineModel;
  @Input() availableDeviceTypeOptions: SelectItem[];

  constructor(private machineService: MachineService,
              private workloadService: WorkloadService,
              private spinnerService: SpinnerService,
              private searchCriteriaService: SearchCriteriaService,
              private crossFunctionalService: CrossFunctionalService,
              private notificationService: NotificationService,
              private formBuilder: FormBuilder,
              private router: Router,
              private pTableControlService: PTableControlService,
              private productionDeviceModelService: ProductionDeviceModelService) {
    this.isCrossFunctionalForProductQualityActive = this.crossFunctionalService
      .isCrossFunctionalActive('savedStateForProductQualityMachinesCross');
  }

  public ngOnInit(): void {
    if (!this.availableDeviceTypeOptions) {
      this.machineService.getAllMachineModelType()
        .subscribe(
          (response) => {
            this.availableDeviceTypeOptions = Utility.getSelectItemArrayFromEnumEntityArray(response);
            this.initFormGroup();
          },
          (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService)
        );
    } else {
      this.initFormGroup();
    }
    this.loadMachineModelWorkloadForTiles();
  }

  private loadMachineModelWorkloadForTiles(): void {
    this.spinnerService.activateSpinner();
    this.workloadService.getMachineModelWorkload(this.searchCriteriaService.createSearchCriteriaForClass(
      'Workload', 'timestamp', false), this.currentDeviceModel.id, 0, 3)
      .subscribe(response => {
        this.workloadList = response;
        this.spinnerService.deactivateSpinner();
      }, (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService));
  }

  public toggleMachineDetailsFlag(): void {
    this.hideMachineDetails = !this.hideMachineDetails;
  }


  public initFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      name: [{value: this.currentDeviceModel.name, disabled: this.isCrossFunctionalForProductQualityActive},
        [Validators.required, Validators.maxLength(ConstanceValues.MAX_STRING_LENGTH)]],
      type: [{
        value: this.availableDeviceTypeOptions.find(item => item.value?.id === this.currentDeviceModel.type?.id)?.value,
        disabled: this.isCrossFunctionalForProductQualityActive
      },
        [Validators.required]],
      costCenter: [{value: this.currentDeviceModel.costCenter, disabled: this.isCrossFunctionalForProductQualityActive},
        [Validators.min(ConstanceValues.MIN_NUMBER), Validators.max(ConstanceValues.MAX_NUMBER), Validators.required]],
      maintenanceCostPerManufacturedUnit: [{
        value: this.currentDeviceModel.maintenanceCostPerManufacturedUnit,
        disabled: this.isCrossFunctionalForProductQualityActive
      },
        [Validators.min(ConstanceValues.MIN_NUMBER), Validators.max(ConstanceValues.MAX_NUMBER), Validators.required]]
    });
  }

  public onSubmit(): void {
    this.spinnerService.activateSpinner();
    this.currentDeviceModel = Object.assign(this.currentDeviceModel, this.formGroup.value);
    this.productionDeviceModelService.updateProductionDeviceModel(this.currentDeviceModel)
      .subscribe(response => {
        Utility.updateObjectInData(response, this.machineService.getMachineModelData());
        this.notificationService.displayNotificationToast('production.machine-model-updated-successfully',
          NotificationMessageType.SUCCESS);
        this.spinnerService.deactivateSpinner();
      }, (error) => {
        this.notificationService.displayNotificationToast(Utility.getErrorMessageFromResponse(error),
          NotificationMessageType.ERROR);
      });
  }

  public returnToProductQuality(): void {
    this.pTableControlService.pushExpandedRowToMap('productQualityTable',
      this.crossFunctionalService.getGatheredData('savedStateForProductQualityMachinesCross').id);
    this.pTableControlService.setOneTimeSelectedTab('productQualityTable',
      'product-quality-catalog.machines');
    this.crossFunctionalService.setGatheredData('wasActionPerformedForCrossOnProductQualityMachine',
      true);
    this.router.navigate(['bh/products/product-quality-catalog']);
  }

  public addMachineToProductQuality(): void {
    const crossObject: { id: string, machine: ProductionDeviceModel } = this.crossFunctionalService
      .getGatheredData('savedStateForProductQualityMachinesCross');
    crossObject.machine = this.currentDeviceModel;
    this.returnToProductQuality();
  }

  public get nameControl(): AbstractControl {
    return this.formGroup.get('name');
  }

  public get typeControl(): AbstractControl {
    return this.formGroup.get('type');
  }

  public get costCenterControl(): AbstractControl {
    return this.formGroup.get('costCenter');
  }


  public get maintenanceCostPerManufacturedUnitControl(): AbstractControl {
    return this.formGroup.get('maintenanceCostPerManufacturedUnit');
  }

}
