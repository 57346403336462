<p-table
  #pTable
  class="main-table-header"
  responsiveLayout="scroll"
  dataKey="id"
  selectionMode="single"
  [value]="getTableData()"
  [rowExpandMode]="'single'"
  (onPage)="paginate($event)"
  [paginator]="!shouldExportBeApplied"
  [lazy]="true"
  [rowHover]="true"
  [(first)]="pageable.pageNumber"
  [rows]="pageable.pageSize"
  [totalRecords]="pageable.count">

  <ng-template pTemplate="header">
    <tr>
      <th class="primeng-table__search-col no-print">
      <th (click)="sortByColumn('name')"
          pSortableColumn="machineName">{{'production.name' | translate}}
        <p-sortIcon class="no-print" field="machineName"></p-sortIcon>
      </th>

      <th (click)="sortByColumn('type')"
          pSortableColumn="machineType">{{'production.machine-type' | translate}}
        <p-sortIcon class="no-print" field="machineType"></p-sortIcon>
      </th>

      <th>{{'production.instances' | translate}}</th>
    </tr>

    <tr
      *ngIf="showFilteringRow"
      class="primeng-table__search-row no-print">
      <th class="primeng-table__search-col"><i class="primeng-table__search-icon"></i></th>

      <td>
        <input
          appStringLengthInputValidation
          type="text"
          placeholder="{{ 'production.search-by-machine-name' | translate }}"
          class="global-input primeng-table__filter-col"
          (keydown.enter)="searchByString($event, 'name')">
      </td>
      <td>
        <p-dropdown
          *ngIf="availableMachineTypeOptions"
          name="role-select"
          class="user-role__action-container__add-role__buttons__select"
          placeholder="{{'production.select-type' | translate}}"
          optionValue="value"
          optionLabel="label"
          [options]="getAvailableMachineTypeOptions()"
          (onChange)="searchByStringWithDropdown($event, 'name', 'type','MachineModelType' )"
          [(ngModel)]="selectedMachineType">
        </p-dropdown>
      </td>
      <td>
      </td>

    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="4">
        {{'common.no-records-available' | translate}}
      </td>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-machine
    let-expanded="expanded"
    class="global-pointer">
    <tr
      [pRowToggler]="machine"
      (click)="clearTabToBeActivated()">
      <td class="no-print">
        <i class="primeng-table__angle-down"
           [ngClass]="expanded ? 'pi pi-angle-down' : 'pi pi-angle-right'"></i>
      </td>
      <td>{{machine.name}}</td>
      <td>{{machine.type?.translations[0].translationValue}}</td>
      <td>{{machine.machineInstanceList.length}}</td>
    </tr>
  </ng-template>

  <ng-template
    pTemplate="rowexpansion"
    let-machine>
    <tr>
      <td class="primeng-table__edit--tab-padding primeng-table__edit--tab--border-top" colspan="4">
        <div class="primeng-table__edit--tab-component">
          <app-tabs
            [tabsMap]="getTabsMap()"
            [defaultActiveTab]="tabToBeActivate"
            (click)="clearTabToBeActivated()">
            <app-tab
              [tabTitle]="('production.machine-details' | translate)"
              [dataBindMap]="bindData(machine)">
            </app-tab>
            <app-tab
              *ngIf="!!!isCrossFunctionalForFurnaceModelActive"
              [tabTitle]="('production.machine-instances' | translate)"
              [dataBindMap]="bindData(machine)">
            </app-tab>
            <app-tab
              *ngIf="!!!isCrossFunctionalForFurnaceModelActive"
              [tabTitle]="('production.workspace-groups' | translate)"
              [dataBindMap]="bindData(machine)"
            ></app-tab>
          </app-tabs>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
